import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Text } from 'grommet';

import CopyableInput from '../CopyableInput';
import selectReferralCode from '../../state/industry/selectReferralCode';

const MemberDiscountCode = ({ layout }) => {
  const referralCode = useSelector(selectReferralCode);
  if (!referralCode) return null;
  switch (layout) {
    case 'inline':
      return <Text>{referralCode}</Text>;
    case 'copyable':
      return <CopyableInput value={referralCode} displayValue={referralCode} />;
    default:
      return <Text>{referralCode}</Text>;
  }
};

MemberDiscountCode.propTypes = {
  layout: PropTypes.oneOf(['inline', 'copyable']),
};

export default memo(MemberDiscountCode);
